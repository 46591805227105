import ChocolateChip from '../../assets/image/cookies/chocolate-chip-cookie.png';
import ChocolatePistachio from '../../assets/image/cookies/white-chocolate-pistachio.png';
import RedVelvetChocolate from '../../assets/image/cookies/red-velvet-white-chocolate.png';
import TripleChocolate from '../../assets/image/cookies/triple-chocolate-cookie.png';
import NutellaToastedHazelnut from '../../assets/image/cookies/nutella-toasted-hazelnut.png';
import MilkChocolate from '../../assets/image/cookies/milk-chocolate.png';
import Brownie1 from '../../assets/image/brownies/brownie1.png'
import Brownie2 from '../../assets/image/brownies/brownie2.png'
import Brownie3 from '../../assets/image/brownies/brownie3.png'
import Teacake1 from '../../assets/image/teacake/teacake1.png'
import Teacake2 from '../../assets/image/teacake/teacake2.png'
// import Celeb from '../../../../assets/image/teacake/celeb.png';
import Celeb from '../../assets/image/teacake/celeb.png'

export const productDetails = [
    {
        id: "1",
        name:"DECADENT BROWNIE",
        image: Brownie1,
        desc1:"",
        desc: "Made with high-quality 70% dark Belgian chocolate, our decadent brownie is rich and indulgent. It provides a deep and complex flavor, with a hint of bitterness making it the go-to brownie for all dark chocolate lovers. With a fudgy center and crackly top, our decadent brownie is a soul-filling choice for your most basic cravings",
        image2: ""



    },
    {
        id: "2",
        name:"TRIPLE CHOCOLATE BROWNIE",
        image: Brownie2,
        desc:"For the chocolate fanatics who cant make up their mind about their favourite type of chocolate, presenting to you our best seller – The Triple Chocolate Brownie. A blend of dark, milk, and white chocolate, our triple chocolate brownies satisfies all your chocolate cravings with its intensely rich and chocolate-filled interior", 
        desc1 :"The chunks of dark, milk, and white chocolate make it dense, moist, and fudgy on the inside. The combination of the three chocolates promises a perfect blend of sweetness and bitterness for your palette.",
        image2: ""



    },
    {
        id: "3",
        name:"NUTELLA BROWNIE",
        image: Brownie3,
        desc1:"",
        desc: "A treat for all Nutella lovers, our Nutella brownie promises a luscious,gooey Nutella-filled center with a crackly top. To enhance the flavour we add hazelnuts for a hint of texture and nuttiness. We also drizzle extra Nutella on top to elevate the overall taste. The fusion of textures makes this a mouth watering and delightful experience.",
        image2: ""



    },
    {
        id: "4",
        name:"BROOKIE",
        image: TripleChocolate,
        desc1:"",
        desc: "For the indecisive ones who can't choose between a brownie or a cookie, introducing – The Brookie. A layer of brownie topped with cookie dough baked to perfection bringing together the fudgy goodness of a brownie and the chewy texture of a cookie. Ensuring an explosion of taste in every bite, our brookie will be your new addiction.",
        image2: ""



    },
    // {
    //     id: "5",
    //     image: NutellaToastedHazelnut,
    //     desc: "Elevate your teatime experience with our exquisite teacakes. Delicately spiced and adorned with delightful toppings,our teacakes are a harmony of flavors and textures. Whether you savor them with a cup of your preferred brew or share them with friends over conversations, these teacakes are a reminder of the simple joys of life",
    //     image2: ""



    // },
    // {
    //     id: "6",
    //     image: MilkChocolate,
    //     desc: "",
    //     image2: ""



    // },
    // {
    //     id: "7",
    //     image: Brownie1,
    //     desc: "",
    //     image2: ""



    // },
    // {
    //     id: "8",
    //     image: Brownie2,
    //     desc: "",
    //     image2: ""



    // },
    // {
    //     id: "9",
    //     image: Brownie3,
    //     desc: "",
    //     image2: ""



    // },
    // {
    //     id: "10",
    //     image: Teacake1,
    //     desc: "",
    //     image2: ""



    // },
    // {
    //     id: "11",
    //     image: Teacake2,
    //     desc: "",
    //     image2: ""



    // },
    // {
    //     id: "12",
    //     image: Celeb,
    //     desc: "",
    //     image2: ""



    // },
]