
import Icon1 from '../../../../assets/image/icon1.svg';
import Icon2 from '../../../../assets/image/icon2.svg';
import Icon3 from '../../../../assets/image/icon3.svg';
// import { NavLink } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getAllCategories } from '../../../../api/product';
import StickyBox from "react-sticky-box";
import './index.css'
const ProductSideBarPage = ({ category }) => {
  console.log(category);

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  const [chatWidth, setChatWidth] = useState(undefined);
  const [sidebarTop, setSidebarTop] = useState(undefined);

  useEffect(() => {
    const chatEl = document.querySelector('.single-sidebar').getBoundingClientRect();
    setChatWidth(chatEl.width);
    setSidebarTop(chatEl.top);
  }, []);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, [sidebarTop]);

  const isSticky = (e) => {
    const chatEl = document.querySelector('.single-sidebar');
    const scrollTop = window.scrollY;
    if (scrollTop >= sidebarTop - 10) {
      chatEl.classList.add('is-sticky');
    } else {
      chatEl.classList.remove('is-sticky');
    }
  };

  return (
    <>


      <div class="col-xl-2 col-lg-5 col-md-12 col-sm-12">
        <div class="single-sidebar" style={{ width: chatWidth }}>
          <ul class="service-pages ">

            {category?.map((category, index) => {
              console.log(category.categoryName.split(" ").join(""))
              return (
              
                <li className="border-list ">
                  < NavHashLink to={"#" +category.categoryName.split(" ").join("")}
                    style={
                      isActive("#" +category.categoryName.split(" ").join(""))
                        ? {
                          background: ' #EFC650',
                          borderRadius: '10px',
                          color: "black"
                        }
                        : { color: "black" }
                    }
                  >
                    <img src={Icon1} class="img-sidebar" />{category.categoryName}</ NavHashLink >
                </li>
              )

            })}

          </ul>

        </div >
      </div >


    </>
  );
}
export default ProductSideBarPage;