

import FooterPage from "../../../../components/footer"
import NavBar from "../../../../components/navbar"
import choc from "../../../../assets/image/salt-cookies.png"
import { Link, useLocation } from "react-router-dom";
const ProductDetailListPage = () => {
    const location = useLocation();
    const propsData = location.state;
    console.log(propsData)

    return (<>
       
       <main className="main">
        <NavBar />
        <section className="product-detail" id="header">
            {propsData != null ? (<div className="row justify-content-center">

                <div className="col-lg-6 col-md-12 md-pb">
                    <img src={choc} className="mob-img" />
                    <div className="sub-detail">
                        <p className="detail-sub1">AED <span className="title-sub12">45/-</span></p>
                        <button className="detail-sub2">Contact Seller</button>
                    </div>
                </div>


                <div className="col-lg-6 col-md-12 md-pb">
                    <div className="title-wrapper align-left">
                        <h2 className="left h1-b"><span>{propsData.name}</span></h2>
                        </div>
                        <p className="about-p1">{(propsData.desc)}</p>
                        <p className="about-p1">{(propsData.desc1)}
                        </p>

                </div>



            </div>) : <div className="row justify-content-center" id='header'>

                <div className="col-lg-6 col-md-12 md-pb">
                    <img src={choc} className="mob-img" />
                    <div className="sub-detail">
                        <p className="detail-sub1">AED <span className="title-sub12">45/-</span></p>
                        <button className="detail-sub2">Contact Seller</button>
                    </div>
                </div>


                <div className="col-lg-6 col-md-12 md-pb">
                    <div className="title-wrapper align-left">
                        <h2 className="left h1-b"><span>Chocolate chip cookie</span></h2>
                        <h6 className="pb-3 about-h1">Specialties</h6>
                        <p className="about-p1">Iskry is strongly contrasted, edgy and blazing serif typeface dedicated for display purposes on screens and in print. The serifs and the terminals are sharp and the axis of the letters is varied, creating unique texture. Large x‑height combined with humble capital letters allow for typesetting short paragraphs and large headlines.Iskry is strongly contrasted, edgy and blazing serif typeface dedicated for display purposes on
                        </p>
                        <h6 className="pb-3 about-h1">Ingredients</h6>
                        <p className="about-p1">Iskry is strongly contrasted, edgy and blazing serif typeface dedicated for display purposes on screens and in print. The serifs and the terminals are sharp and the axis of the letters is varied, creating unique texture. Large x‑height combined with humble capital letters allow for typesetting short paragraphs and large headlines.Iskry is strongly contrasted, edgy and blazing serif typeface dedicated for display purposes on
                        </p>
                    </div>
                </div>



            </div>}


        </section>
        <FooterPage />
        
        </main >
    </>)
}
export default ProductDetailListPage