// !--===============Component================--!
import NavBar from "../../components/navbar";
import FooterPage from "../../components/footer";
import ProductSideBarPage from "./components/sidebar";
import ProductListPage from "./components/productlist";
// !--===============imageImport==================--!
import BannerProduct from '../../assets/image/banner-product.png';
// import StickyBox from "react-sticky-box";

import { useQuery } from '@tanstack/react-query';
import { getAllCategories, getAllProducts } from "../../api/product";

const ChocolateProductPage = () => {

    //Api

    //category
    const categoriesResponse = useQuery({
        queryKey: ['categories'],
        queryFn: getAllCategories
    })

    if (categoriesResponse.isSuccess) {
        const categories = categoriesResponse.data.data.data
        var categoriesDetail = categories.map(category => ({
            id: category?._id ?? "",
            categoryName: category?.categoryName ?? "",
            description: category?.description ?? ""
        }))
    }



    return (
        <>
            <NavBar />
            <main className="main">
                {/* <!--==================== HOME ====================--> */}
                <section className="home" id="home">
                    <img src={BannerProduct} alt="" className="home__img" />
                    <div className="home__container container grid">
                    </div>
                </section>
                {/* <!--==================== Box ====================-->        */}
                <section className="single-service-area" >

                    <div className="row mg-2">

                        <ProductSideBarPage
                            key={categoriesDetail}  // rerender Category each time categoriesDetail changes
                            category={categoriesDetail}
                        />

                        {/* </div> */}
                        <div className="col-xl-10 col-lg-7 col-md-12 col-sm-12">
                            <ProductListPage
                                key={categoriesDetail}  // rerender Products each time productsDetail changes
                                category={categoriesDetail}
                            />
                        </div>
                    </div>
                </section>
            </main>
            {/* <!--==================== FOOTER ====================--> */}
            < FooterPage />
        </>
    )
}
export default ChocolateProductPage