import { ReactComponent as Contact } from '../../assets/image/contact.svg';
import { ReactComponent as Cart } from '../../assets/image/cart.svg';
import { ReactComponent as Search } from '../../assets/image/search.svg';
import { ReactComponent as Logo } from '../../assets/image/cart.svg'
import LogoMain from '../../assets/image/logo-main.png'
import { NavLink } from 'react-router-dom';
import './index.css'

const NavBar = () => {
    return (<>
        <header className="header" id="header">
            <nav className="nav container">
                <div className="nav__logo"><img src={LogoMain} className="img-log" /></div>

                <div className="nav__menu" id="nav-menu">
                    <ul className="nav__list">
                        <li className="nav__item">
                            <NavLink   style={{textDecoration: 'none'}} to="/" className="nav__links ">Home</NavLink>
                        </li>
                        <li className="nav__item">
                            <NavLink style={{textDecoration: 'none'}}  to='/product' className="nav__links">Products</NavLink>
                        </li>
                        <li className="nav__item">
                        <NavLink style={{textDecoration: 'none'}} to='/contact-us' className="nav__links">Contact Us</NavLink>
                        </li>

                    </ul>

                    <ul className="nav__list">
                        <li className="nav__item">
                            <a href="#"><Contact /></a>
                        </li>
                        <li className="nav__item">
                            <a href="#about"><Cart /></a>
                        </li>
                        <li className="nav__item">
                            <a href="#discover">< Search /></a>
                        </li>

                    </ul>

                    <i className="ri-close-line nav__close" id="nav-close"></i>
                </div>

                <div className="nav__toggle" id="nav-toggle">
                    <i className="ri-function-line"></i>
                </div>
            </nav>
        </header>
    </>)
}
export default NavBar;