import axios from "axios"

export function getAllCategories() {
    return axios.get("https://ck.accruontechnologies.co.in/chocolateApi/getAllCategories")
}


export function getAllProducts() {
    return axios.get("https://ck.accruontechnologies.co.in/chocolateApi/getAllProducts")
}




