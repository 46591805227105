import { useQuery } from '@tanstack/react-query';
import { getAllProducts } from '../../../../api/product';

import ChocolateChip from '../../../../assets/image/cookies/chocolate-chip-cookie.png';
import ChocolatePistachio from '../../../../assets/image/cookies/white-chocolate-pistachio.png';
import RedVelvetChocolate from '../../../../assets/image/cookies/red-velvet-white-chocolate.png';
import TripleChocolate from '../../../../assets/image/cookies/triple-chocolate-cookie.png';
import NutellaToastedHazelnut from '../../../../assets/image/cookies/nutella-toasted-hazelnut.png';
import MilkChocolate from '../../../../assets/image/cookies/milk-chocolate.png';
import Brownie1 from '../../../../assets/image/brownies/brownie1.png'
import Brownie2 from '../../../../assets/image/brownies/brownie2.png'
import Brownie3 from '../../../../assets/image/brownies/brownie3.png'
import Teacake1 from '../../../../assets/image/teacake/teacake1.png'
import Teacake2 from '../../../../assets/image/teacake/teacake2.png'
import Celeb from '../../../../assets/image/teacake/celeb.png';
import { Link } from 'react-router-dom';
import NavBar from '../../../../components/navbar';
import { productDetails } from '../../../../components/jsonfile';
import { useState } from 'react';


const ProductListPage = ({ category }) => {
   
    //product 

    const productsResponse = useQuery({
        queryKey: ['products'],
        queryFn: getAllProducts
    })
    console.log(productsResponse);

    if (productsResponse.isSuccess) {
        const products = productsResponse.data.data.data
        console.log(products);
        var productsDetail = products.map(product => ({
            id: product?._id ?? "",
            category: product?.category ?? "",
            description: product?.description ?? "",
            images: product?.images ?? "",
            name: product?.name ?? "",
            price: product?.price ?? ""
        }))
    }
    

    return (
        <>

            {/* {category?.map((category, index) => {
                return (
                    <div id={category.categoryName}>
                        <h1 classNameName="head-cookie">{category.categoryName}</h1>
                        <div classNameName="container21">
                            <div classNameName="row">

                                {productsDetail?.filter((val) => (val.category == category.id))
                                    .map((product, index) => {
                                        return (
                                            < div classNameName="col-md-4 first-row first-row" >
                                                <img
                                                   
                                                    src={product.images}
                                                
                                                />
                                                <div classNameName="title-one">
                                                    <p classNameName="img-p1">{product.name}</p>
                                                    <div className="sub-title">
                                                        <p className="title-sub1">AED <span className="title-sub12">{product.price}</span></p>
                                                        <button className="title-sub2">Contact Seller</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div >
                        </div >
                    </div >
                )
            })} */}
 
            <div className="col-xl-10 col-lg-7 col-md-12 col-sm-12">
                <div className="main-sec1">
                    {/* <!--Cookies--> */}
                    <h1 className="head-cookie" id="Cookie">Cookies</h1>
                    <div className="container21">
                        <div className="row">

                            <div className="col-md-4 first-row">
                                <Link to="/product/details" >
                                    <img src={ChocolateChip} />
                                    <div className="title-one">
                                        <p className="img-p1">Chocolate chip cookie</p>
                                        <div className="sub-title">
                                            <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                            <button className="title-sub2">Contact Seller</button>
                                        </div>

                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-4 first-row">
                                <Link to="/product/details">
                                    <img src={ChocolatePistachio} />
                                    <div className="title-one">
                                        <p className="img-p1">Chocolate chip cookie</p>
                                        <div className="sub-title">
                                            <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                            <button className="title-sub2">Contact Seller</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 first-row">
                                <Link to="/product/details">
                                    <img src={RedVelvetChocolate} />
                                    <div className="title-one">
                                        <p className="img-p1">Chocolate chip cookie</p>
                                        <div className="sub-title">
                                            <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                            <button className="title-sub2">Contact Seller</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-md-4 first-row">
                                <Link to="/product/details">
                                    <img src={TripleChocolate} />
                                    <div className="title-one">
                                        <p className="img-p1">Chocolate chip cookie</p>
                                        <div className="sub-title">
                                            <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                            <button className="title-sub2">Contact Seller</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 first-row">
                                <Link to="/product/details">
                                    <img src={NutellaToastedHazelnut} />
                                    <div className="title-one">
                                        <p className="img-p1">Chocolate chip cookie</p>
                                        <div className="sub-title">
                                            <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                            <button className="title-sub2">Contact Seller</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 first-row">
                                <Link to="/product/details">
                                    <img src={MilkChocolate} />
                                    <div className="title-one">
                                        <p className="img-p1">Chocolate chip cookie</p>
                                        <div className="sub-title">
                                            <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                            <button className="title-sub2">Contact Seller</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 first-row">
                                <Link to="/product/details">
                                    <img src={MilkChocolate} />
                                    <div className="title-one">
                                        <p className="img-p1">Chocolate chip cookie</p>
                                        <div className="sub-title">
                                            <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                            <button className="title-sub2">Contact Seller</button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* <!--Brownies--> */}
                    <h1 className="head-cookie" id="Brownie">Brownies</h1>
                    <div className="container21">
                        <div className="row">
                            {productDetails.map((item,index) => {
                                return (<>
                                    <div className="col-md-4 first-row" >
                                        <Link to="/product/details" state={item}>
                                            <img src={item.image} />
                                            <div className="title-one">
                                                <p className="img-p1">{item.name}</p>
                                                <div className="sub-title">
                                                    <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                                    <button className="title-sub2">Contact Seller</button>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </>)
                            })}
                            {/* <div className="col-md-4 first-row">
                <Link to="/product/details">
                  <img src={Brownie1}/>
                 <div className="title-one">
                  <p className="img-p1">8 Piece brownie stack</p>
                  <div className="sub-title">
                      <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                      <button className="title-sub2">Contact Seller</button>
                  </div>
              </div>
              </Link>
              </div> */}
                            {/* <div className="col-md-4 first-row">
              <Link to="/product/details">
                  <img src={Brownie2}/>
                 <div className="title-one">
                  <p className="img-p1">12 Piece brownie stack</p>
                  <div className="sub-title">
                      <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                      <button className="title-sub2">Contact Seller</button>
                  </div>
              </div>
              </Link>
              </div> */}
                            {/* <div className="col-md-4 first-row">
              <Link to="/product/details">
                  <img src={Brownie3}/>
                 <div className="title-one">
                  <p className="img-p1">16 Piece brownie stack</p>
                  <div className="sub-title">
                      <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                      <button className="title-sub2">Contact Seller</button>
                  </div>
              </div>
              </Link>
              </div> */}



                            {/* <div className="col-md-4 first-row">
    <Link to="/product/details">
                <img src={Brownie1}/>
                 <div className="title-one">
                  <p className="img-p1">Decadent brownie slab</p>
                  <div className="sub-title">
                      <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                      <button className="title-sub2">Contact Seller</button>
                  </div>
              </div>
              </Link>
              </div> */}
                            {/* <div className="col-md-4 first-row">
             
                <img src={Brownie1}/>
                 <div className="title-one">
                  <p className="img-p1">Nutella slab</p>
                  <div className="sub-title">
                      <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                      <button className="title-sub2">Contact Seller</button>
                  </div>
              </div>
              </div> */}
                            {/* <div className="col-md-4 first-row">
                <img src={Brownie3}/>
                 <div className="title-one">
                  <p className="img-p1">Triple chocolate slab</p>
                  <div className="sub-title">
                      <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                      <button className="title-sub2">Contact Seller</button>
                  </div>
              </div>
              </div> */}



                            {/* <div className="col-md-4 first-row">
                <img src={Brownie2}/>
                 <div className="title-one">
                  <p className="img-p1">Assorted Brownies (box of 6pcs)<br/>(On selected Days)</p>
                  <div className="sub-title">
                      <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                      <button className="title-sub2">Contact Seller</button>
                  </div>
              </div>
              </div> */}
                            {/* <div className="col-md-4 first-row">
                <img src={Brownie1}/>
               <div className="title-one">
                <p className="img-p1">Assorted Brownies (box of 12pcs)<br/>(On selected Days)</p>
                <div className="sub-title">
                    <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                    <button className="title-sub2">Contact Seller</button>
                </div>
            </div>
            </div> */}
                            {/* <div className="col-md-4 first-row">
                <img src={Brownie2}/>
               <div className="title-one">
                <p className="img-p1">Assorted Brownies (box of 16pcs)<br/>(On selected Days)</p>
                <div className="sub-title">
                    <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                    <button className="title-sub2">Contact Seller</button>
                </div>
            </div>
            </div> */}



                            {/* <div className="col-md-4 first-row">
                <img src={Brownie2}/>
               <div className="title-one">
                <p className="img-p1">Butter tea cake(400g)</p>
                <div className="sub-title">
                    <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                    <button className="title-sub2">Contact Seller</button>
                </div>
            </div>
            </div> */}
                            {/* <div className="col-md-4 first-row">
                <img src={Brownie1}/>
               <div className="title-one">
                <p className="img-p1">Date & walnut toffee cake (500g)</p>
                <div className="sub-title">
                    <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                    <button className="title-sub2">Contact Seller</button>
                </div>
            </div>
            </div> */}
                        </div>
                    </div>


                    {/* <!--Cookies--> */}
                    <h1 className="head-cookie" id='Teacakes'>Tea cakes</h1>
                    <div className="container21">

                        <div className="row">

                            <div className="col-md-4 first-row">
                                <img src={Teacake1} />
                                <div className="title-one">
                                    <p className="img-p1">8 Piece brownie stack</p>
                                    <div className="sub-title">
                                        <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                        <button className="title-sub2">Contact Seller</button>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-4 first-row">
                                <img src={Teacake2} />
                                <div className="title-one">
                                    <p className="img-p1">12 Piece brownie stack</p>
                                    <div className="sub-title">
                                        <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                        <button className="title-sub2">Contact Seller</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <!--Celebration Brownie Box--> */}
                    <h1 className="head-cookie" id='BrownieBox'>Celebration Brownie Box</h1>
                    <div className="container21">
                        <div>
                            <div className="col-md-4 first-row">
                                <img src={Celeb} />
                                <div className="title-one">
                                    <p className="img-p1">8 Piece brownie stack</p>
                                    <div className="sub-title">
                                        <p className="title-sub1">AED <span className="title-sub12">45/-</span></p>
                                        <button className="title-sub2">Contact Seller</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


           


        </>
    )
}
export default ProductListPage;