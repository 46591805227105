
import NavBar from "../../components/navbar";
import FooterPage from "../../components/footer";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@tanstack/react-query";
import { message } from "antd"
import { saveCustomerContactsForm } from "../../api/user";

const UserContactPage = () => {

  const { register, handleSubmit } = useForm();
  const [messageApi, messageContextHolder] = message.useMessage()
  // api call to save the form fields
  const saveForm = useMutation({
    mutationFn: (payload) => saveCustomerContactsForm(payload),
    onMutate: () => {
      messageApi.open({
        key: "saveForm",
        type: "loading",
        content: "Saving..."
      })
    },
    onSuccess: () => {
      messageApi.open({
        key: "saveForm",  // key same as previous message so that it is replaced
        type: "success",
        content: "Saved successfully!",
        duration: 2
      })
    },
    onError: () => {
      messageApi.open({
        key: "saveForm",
        type: "error",
        content: "Failed to save!"
      })
    }
  })

  const handleUserDetails = (values) => {

    const data = {
      customerFirstName: values.firstName,
      customerLastName: values.lastName,
      email: values.email,
      phone: values.phone,
      type: values.contactoption,
      description: "null",
      address: values.address
    }

    saveForm.mutate(data)

  }



  return (
    <>
      <NavBar />
      {/* <!--==================== Box ====================-->
       */}
      <div class="main-contact">
        <h2 class="contact-head">Book An Appointment</h2>

        <p class="contact-paragraph">We would like to hear from you. Share your feedback,
          recommendations, thoughts and complaints. Please fill
          in the form below and our consultant will get in touch
          with you shortly.</p>


        <form onSubmit={handleSubmit(handleUserDetails)} >
          <div class="row">
            <div class="col contact-in">
              <input type="text" class="form-control mt-3 contact-input" placeholder="First name"  {...register('firstName')} />
            </div>
            <div class="col">
              <input type="text" class="form-control mt-3 contact-input" placeholder="Last name"   {...register('lastName')} />
            </div>
          </div>

          <div class="row">
            <div class="col contact-in">
              <input type="email" class="form-control mt-3 contact-input" placeholder="Email"    {...register('email')} />
            </div>
            <div class="col">
              <input type="number" class="form-control mt-3 contact-input" placeholder="Phone"    {...register('phone')} />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <select class="select-contact mt-3"   {...register('contactoption')}>
                <option>Choose option</option>
                <option>Option 1</option>
                <option>Option 2</option>
                <option>Option 3</option>
              </select>
            </div>

          </div>

          <div class="row">
            <div class="col">
              <textarea class="select-contact mt-3 contact-input contact-one" required="" type="text" placeholder="Type your message here"     {...register('address')}></textarea>
            </div>

          </div>

          <button class="contact-sub">Submit</button>

        </form>




      </div>


      < FooterPage />
    </>
  )
}
export default UserContactPage