import NavBar from '../../components/navbar'
import FooterPage from '../../components/footer'
import { ReactComponent as Sign } from '../../assets/image/sign.svg';
import Butter from '../../assets/image/butter.svg'
import Choco from '../../assets/image/choco.svg'
import Dough from '../../assets/image/dough.png'
import BannerImage from '../../assets/image/banner-img.png'
import Cookies from '../../assets/image/cookies.png'

import CupCake from '../../assets/image/Tea cake v1.jpg'
import Brownie from '../../assets/image/brownie.png';
import About from '../../assets/image/about.png';

import Cookie1 from '../../assets/image/cookie1.png';
import Cupcake2 from '../../assets/image/cupcake2.png';
import Cake from '../../assets/image/cake.png';
import Macroni from '../../assets/image/macroni.png';
import Cup from '../../assets/image/cup.png';
import Client from '../../assets/image/client.jpg'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// /home/accruon-react/chocolate-dough/src/assests/image
import "../../assets/css/styles.css"
import './index.css'
import React from 'react';
const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 2500;

function ChocolateDoughHome() {



    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,

            slidesToSlide: 2
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 3,
            slidesToSlide: 2
        },

        tablet: {
            breakpoint: { max: 1300, min: 880 },
            items: 2,
            slidesToSlide: 2

        },
        mobile: {
            breakpoint: { max: 880, min: 0 },
            items: 1
        }
    };
    return (<>
        <>
            <NavBar />


            <main className="main">

                {/* ==================== HOME ==================== */}

                <div className="home" id="home">
                    <img src={BannerImage} alt="" className="home__img" />

                    <div className="home__container container grid">

                        <div className="home__info">

                            <div className="home__info-overlay">
                                <img src={Client} alt="" className="home__info-img" />
                            </div>
                            <div>
                                <span className="home__info-title">Emily James</span>
                                <p className="client-p">Indulge in divine pleasures with our fudgy brownies, chewy cookies, and
                                    delicate   tea   cakes.   Crafted   with   love,   each   bite   is   an   escape   into
                                    sweetness. Elevate your moments with our baked delights.</p>
                                <Sign />
                            </div>


                        </div>
                    </div>
                </div>

                {/* ==================== ABOUT ==================== */}
                <div className="about section" id="about">
                    <div className="about__container container grid">
                        <div className="about__data">

                            <div className="three-column-images">
                                <div>
                                    <div><img src={Choco} /></div>
                                    <h4 className="choc-one">Chocolate</h4>
                                    <p className="choc-two">Decadent Belgium chocolate enhances the flavors of cookies, tea cakes,
                                        and   brownies,   transforming   them   into   heavenly   treats   of   unparalleled
                                        indulgence. </p>
                                </div>
                                <div>
                                    <img src={Dough} />
                                    <h4 className="choc-one">Dough</h4>
                                    <p className="choc-two">Through   the   meticulous   selection   and   artful   combination   of   premium
                                        grains,   the   dough   is   transformed   into   the   cornerstone   of   irresistibly
                                        textured creations, encompassing a variety of delectable treats. </p>
                                </div>
                                <div>
                                    <img src={Butter} />
                                    <h4 className="choc-one">Butter</h4>
                                    <p className="choc-two">Velvety Irish butter enhances the flavors and textures of cookies, tea
                                        cakes,   and   brownies,   infusing   them   with   an   unmatched   richness   that
                                        elevates each bite into a symphony of indulgence. </p>
                                </div>
                            </div>


                        </div>



                    </div>
                </div>

                {/* ==================== Box ==================== */}

                <div className="box">

                    <div className="box-1">
                        <img src={Cookies} />
                        <div className="text-block">
                            <p>View More</p>
                        </div>
                    </div>

                    <div className="box-1">
                        <img src={CupCake} />
                        <div className="text-block">
                            <p>View More</p>
                        </div>
                    </div>


                    <div className="box-1">
                        <img src={Brownie} />
                        <div className="text-block">
                            <p>View More</p>
                        </div>
                    </div>

                </div>


                {/* ==================== Img ==================== */}

                <div className="container1">
                    <div className="text">
                        <h4 className="choco1">#chocolate dough butter</h4>
                        <h1 className="choco2">It’s taste of paradise.</h1>
                        <p className="choco3">
                            At Chocolate Dough Butter, we specialize in crafting indulgent moments
                            through our exquisite array of brownies, cookies, and delicate tea cakes.
                            With  a  shared  passion  for  baking  and  an  unwavering  commitment  to
                            quality, our team brings a touch of artistry to every creation</p>
                        <p className="choco3">Our journey began with a simple belief: that the joy of life can be savored
                            in   the   form   of   a   perfect   treat.   We   meticulously   select   the   finest
                            ingredients, from rich cocoa to velvety chocolate, ensuring that each bite
                            is a harmonious symphony of flavors</p>
                        <p className="choco3">From the comforting nostalgia of a classic chocolate chip cookie to the
                            nuanced elegance of our tea cakes, every product is a testament to our
                            dedication to creating edible delights that transport you to moments of
                            pure happiness.</p>
                        <p className="choco3">As you explore our offerings, you're not just indulging in baked goods –
                            you're becoming a part of our story. Join us in celebrating life's simplest
                            pleasures, one scrumptious creation at a time.</p>

                    </div>
                    <div className="image"><p className="about-sec">About us</p>
                        <img src={About} className="image1" />

                    </div>

                </div>
                <div className="testimonial-one">
                    <h3 className="frame-11">Every flavor has a story</h3>
                    <Carousel className='slide-one' responsive={responsive} autoPlay={true} autoPlaySpeed={2000} infinite={true}>


                        <div className="main-frame">
                            <div className="frame-4">
                                <img className="rectangle-1" src={CupCake} />

                                <div className="group-15">
                                    {/* <div className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </div> */}

                                    {/* <div className="may-8-2020">May 8, 2020</div> */}

                                    <div
                                        className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-cursus-nibh-mauris-nec-turpis-orci-lectus-maecenas-suspendisse-sed-magna-eget-nibh-in-turpis-consequat-duis-diam-lacus-arcu-faucibus-venenatis-felis-id-augue-sit-cursus-pellentesque-enim-arcu-elementum-felis-magna-pretium-in-tincidunt-suspendisse">
                                        Elevate your teatime experience with our exquisite
                                        teacakes. Delicately spiced and adorned with delightful toppings, our
                                        teacakes are a harmony of flavors and textures. Whether you savor them
                                        with a cup of your preferred brew or share them with friends over
                                        conversations, these teacakes are a reminder of the simple joys of life
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="main-frame">
                            <div className="frame-4">
                                <img className="rectangle-1" src={Cookies} />

                                <div className="group-15">
                                    {/* <div className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </div> */}

                                    {/* <div className="may-8-2020">May 8, 2020</div> */}

                                    <div
                                        className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-cursus-nibh-mauris-nec-turpis-orci-lectus-maecenas-suspendisse-sed-magna-eget-nibh-in-turpis-consequat-duis-diam-lacus-arcu-faucibus-venenatis-felis-id-augue-sit-cursus-pellentesque-enim-arcu-elementum-felis-magna-pretium-in-tincidunt-suspendisse">
                                        Each   cookie   is   carefully   handcrafted   using   real   butter,
                                        premium flour, and a generous helping of your favourite mix-ins – whether
                                        it's chunks of velvety chocolate, toasted nuts, or vibrant sprinkles. Crispy
                                        on   the   edges   and   irresistibly   soft   in   the   centre,   these   cookies   are   a
                                        testament to our dedication to perfection
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="main-frame">
                            <div className="frame-4">
                                <img className="rectangle-1" src={CupCake} />

                                <div className="group-15">
                                    {/* <div className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </div>

                                    <div className="may-8-2020">May 8, 2020</div> */}

                                    <div
                                        className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-cursus-nibh-mauris-nec-turpis-orci-lectus-maecenas-suspendisse-sed-magna-eget-nibh-in-turpis-consequat-duis-diam-lacus-arcu-faucibus-venenatis-felis-id-augue-sit-cursus-pellentesque-enim-arcu-elementum-felis-magna-pretium-in-tincidunt-suspendisse">
                                        Elevate your teatime experience with our exquisite
                                        teacakes. Delicately spiced and adorned with delightful toppings, our
                                        teacakes are a harmony of flavors and textures. Whether you savor them
                                        with a cup of your preferred brew or share them with friends over
                                        conversations, these teacakes are a reminder of the simple joys of life
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="main-frame">
                            <div className="frame-4">
                                <img className="rectangle-1" src={Brownie} />

                                <div className="group-15">
                                    {/* <div className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </div> */}

                                    {/* <div className="may-8-2020">May 8, 2020</div> */}

                                    <div
                                        className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-cursus-nibh-mauris-nec-turpis-orci-lectus-maecenas-suspendisse-sed-magna-eget-nibh-in-turpis-consequat-duis-diam-lacus-arcu-faucibus-venenatis-felis-id-augue-sit-cursus-pellentesque-enim-arcu-elementum-felis-magna-pretium-in-tincidunt-suspendisse">
                                        Indulge in the rich,fudgy decadence of our signature brownies.
                                        Crafted with the finest Belgian chocolate and locally sourced organic eggs, these brownies boast a melt-in-your-mouth texture and a
                                        harmonious blend of flavors. Whether you enjoy them on their own or
                                        paired with a scoop of artisanal ice cream, our brownies are the ultimate comfort dessert
                                    </div>
                                </div>
                            </div>

                        </div>



                    </Carousel>
                </div>
                <div className="w-one">
                    <div className="item1 frame-11">Every flavor has a story</div>
                    <div className="item2 about-sec2">Gallery</div>

                </div>



                <div className="row">
                    <div className="column">

                        <div className="box">
                            <img src={Cookie1} />
                        </div>

                        <div className="box">
                            <img src={Cupcake2} />
                        </div>




                    </div>
                    <div className="column">
                        <div className="box">
                            <img src={Cake} />
                        </div>

                    </div>
                    <div className="column">
                        <div className="box">
                            <img src={Macroni} />
                        </div>
                        <div className="box">
                            <img src={Cup} />
                        </div>

                    </div>
                </div>
            </main>

            {/* ==================== FOOTER ==================== */}
            < FooterPage />
        </>
    </>
    );
}
export default ChocolateDoughHome;

