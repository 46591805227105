import { Navigate, Route, Routes } from 'react-router-dom';
import ChocolateDoughHome from './routes/Home';
import ChocolateProductPage from './routes/product';
import UserContactPage from './routes/usercontact';
import ProductDetailListPage from './routes/product/components/productdetails';
import './App.css';

function App() {
  return (
    <>
    <Routes>
        <Route path="/"element={<ChocolateDoughHome />}  />
        <Route path="/product" element={<ChocolateProductPage/>} />
        <Route path="/product/details" element={<ProductDetailListPage/>}/>
        <Route path="/contact-us" element={ <UserContactPage/> } />
      </Routes>
      </>
   
  );
}

export default App;
