
import axios from "axios"

// export function addCustomerContacts() {

//     const config = {
//         headers: { Authorization: "Bearer " }
//     }
//     const data = {
//         verifierId: "id",
//         docRefId: "",
//         id: ""
//     }
//     return axios.post("https://ck.accruontechnologies.co.in/chocolateApi/addCustomerContacts", data, config)
// }

// Save customer contact form values
export function saveCustomerContactsForm(data) {

    // const config = {
    //     headers: { Authorization: "Bearer " + token }
    // }
    data = {
        ...data
    }
    return axios.post("https://ck.accruontechnologies.co.in/chocolateApi/addCustomerContacts", data)
}